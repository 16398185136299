import axios from '@/common/utils/axios'
import { strictParse } from '@/common/utils/parsing-utils/strict-parse'
import {
  UnreadMessagesCounterResponse,
  ChatsPaginatedResponse,
  MessagesPaginatedResponse,
} from '../types/conversations-data-parsers'
import type { AxiosRequestConfig } from 'axios'

/**
 * Will return a number from 0 to 100
 * (for performance reason 100 is the highest number, since we only display 99+ for this counter)
 */
export const fetchUnreadMessagesCount = async () => {
  // TODO: update endpoint when it is ready
  const { data } = await axios.get<UnreadMessagesCounterResponse>('/xhr/notify/whatsapp/messages/unread')
  const parsedData = strictParse(data, UnreadMessagesCounterResponse, 'fetchUnreadMessagesCount', {
    isEnabledInProd: true,
  })
  return parsedData.count
}

type FetchChatsPayload = {
  cursor: string | null
}
export const fetchChats = async ({ cursor }: FetchChatsPayload): Promise<ChatsPaginatedResponse> => {
  // TODO: temp, uncomment for testing
  // await new Promise(res => setTimeout(() => res(null), 1000))
  // const chatsFix = whatsappChatsFactory()
  // return {
  //   data: chatsFix,
  //   next: 'next-cursor',
  //   previous: 'prev-cursor',
  // }

  const url = cursor || '/xhr/notify/whatsapp/chats'
  const response = await axios.get<ChatsPaginatedResponse>(url)

  // check if types are correct, probably next will be in the body, not headers
  // If it will be in headers - need to update ChatsPaginatedResponse, delete next key from it
  const { data, next, previous } = strictParse(response.data, ChatsPaginatedResponse, 'fetchWhatsAppChatsPage', {
    isEnabledInProd: true,
  })
  return { data, next, previous }
}

type FetchMessagesPayload = {
  chatId: string
  cursor: string | null
}
export const fetchMessages = async (
  { chatId, cursor }: FetchMessagesPayload,
  config?: AxiosRequestConfig<FetchMessagesPayload>,
): Promise<MessagesPaginatedResponse> => {
  // TODO: temp: uncomment for testing
  // await new Promise(res => setTimeout(() => res(null), 1000))
  // const messagesFix = whatsappMessagesFactory(20)
  // return {
  //   data: messagesFix,
  //   next: 'next-cursor',
  //   previous: 'prev-cursor',
  // }

  const url = cursor || `/xhr/notify/whatsapp/chats/${chatId}/messages`

  const response = await axios.get<MessagesPaginatedResponse>(url, config)

  // check if types are correct, probably next will be in the body, not headers
  // If it will be in headers - need to update ChatsPaginatedResponse, delete next key from it
  const { data, next, previous } = strictParse(response.data, MessagesPaginatedResponse, 'fetchWhatsAppMessagesPage', {
    isEnabledInProd: true,
  })
  return { data, next, previous }
}
